import { ApiClient } from '@/lib/axios';
import type { 
  Chatbot,
  CreateChatbotDto,
  UpdateChatbotDto,
  ChatbotFilters,
  UpdateSettingsDto,
  ReportFilters,
} from './types';
import type { PaginatedData } from '@/lib/axios/types';

const BASE_PATH = '/api/v1/chatbots';

export class ChatbotApi {
  static async getAll(filters?: ChatbotFilters): Promise<PaginatedData<Chatbot>> {
    return await ApiClient.get<PaginatedData<Chatbot>>(BASE_PATH, {
      params: filters,
    });
  }

  static async getById(id: string): Promise<Chatbot> {
    return await ApiClient.get<Chatbot>(`${BASE_PATH}/${id}`);
  }

  static async create(data: CreateChatbotDto): Promise<Chatbot> {
    return await ApiClient.post<Chatbot>(BASE_PATH, data);
  }

  static async update(id: string, data: UpdateChatbotDto): Promise<Chatbot> {
    return await ApiClient.patch<Chatbot>(`${BASE_PATH}/${id}`, data);
  }

  static async delete(id: string): Promise<void> {
    await ApiClient.delete<Chatbot>(`${BASE_PATH}/${id}`);
  }

  static async updateSettings(id: string, data: UpdateSettingsDto): Promise<Chatbot> {
    return await ApiClient.post<Chatbot>(`${BASE_PATH}/${id}/settings`, data);
  }

  static async getReport(id: string, filters?: ReportFilters): Promise<PaginatedData<Chatbot>> {
    return await ApiClient.get<any>(`${BASE_PATH}/${id}/report`,{
      params: filters,
    });
  }
}