import { AxiosError } from 'axios';
import { axiosInstance } from './config';
import type { 
  ApiResponse, 
  PaginatedApiResponse,
  ApiError, 
  RequestConfig,
  PaginatedData,
  ErrorResponse
} from './types';

export class ApiClient {
  private static handleError(error: AxiosError<ErrorResponse>): never {
    const apiError: ApiError = {
      message: 'An unexpected error occurred',
      status: error.response?.status,
    };

    if (error.response) {
      // Server responded with error
      const errorData = error.response.data;
      
      if (errorData.data) {
        apiError.message = errorData.data.message;
        apiError.code = errorData.data.code;
      } else {
        apiError.message = error.message;
      }
    } else if (error.request) {
      // Request made but no response
      apiError.message = 'No response received from server';
    }

    throw apiError;
  }

  private static validateResponse<T>(response: any): ApiResponse<T> | PaginatedApiResponse<T> {
    if (!response.data || !response.data.status) {
      throw new Error('Invalid API response format');
    }

    if (response.data.status === 'error') {
      throw {
        message: response.data.data.message,
        code: response.data.data.code,
      };
    }

    return response.data;
  }

  static async get<T extends object>(url: string, config?: RequestConfig): Promise<T> {
    try {
      const response = await axiosInstance.get(url, config);
      const validatedResponse = this.validateResponse<T>(response);
      return validatedResponse.data;
    } catch (error) {
      return this.handleError(error as AxiosError<ErrorResponse>);
    }
  }

  static async post<T extends object>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    try {
      const response = await axiosInstance.post(url, data, config);
      const validatedResponse = this.validateResponse<T>(response);
      return validatedResponse.data;
    } catch (error) {
      return this.handleError(error as AxiosError<ErrorResponse>);
    }
  }

  static async put<T extends object>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    try {
      const response = await axiosInstance.put(url, data, config);
      const validatedResponse = this.validateResponse<T>(response);
      return validatedResponse.data;
    } catch (error) {
      return this.handleError(error as AxiosError<ErrorResponse>);
    }
  }

  static async patch<T extends object>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    try {
      const response = await axiosInstance.patch(url, data, config);
      const validatedResponse = this.validateResponse<T>(response);
      return validatedResponse.data;
    } catch (error) {
      return this.handleError(error as AxiosError<ErrorResponse>);
    }
  }

  static async delete<T extends object>(url: string, config?: RequestConfig): Promise<T> {
    try {
      const response = await axiosInstance.delete(url, config);
      const validatedResponse = this.validateResponse<T>(response);
      return validatedResponse.data;
    } catch (error) {
      return this.handleError(error as AxiosError<ErrorResponse>);
    }
  }
}