import { Alert, AlertIcon, AlertTitle, AlertDescription, Code, IconButton, useToast } from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';

export const InfoMessage = () => {
  const sheetsServiceId = import.meta.env.VITE_SHEET_SERVICE_ID 
  const toast = useToast()

  const copyUrl = (event: React.MouseEvent<HTMLButtonElement>, url: string) => {
    event.preventDefault()
    navigator.clipboard.writeText(`${url}`)
    toast({ title: `Copied to clipboard!`, status: 'success', duration: 3000, isClosable: true })
  }

  return (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="flex-start"
      p={4}
      bg="#faeed9"
      rounded="md"
      mb={6}
    >
      <AlertIcon color="whatsapp.500" />
      <AlertTitle mt={2} mb={2} color="#4d3d2d" fontSize="lg">
        Important: Sheet Permissions Required
      </AlertTitle>
      <AlertDescription color="gray.600">
        Before creating a chatbot, please ensure you've added the required permissions to your Google Sheet.
        <br/> Add 
        <Code bg="#faeed9" color="#4d3d2d" fontWeight="semibold" fontSize="md">
        <IconButton
            variant='ghost'
            aria-label='Copy URL'
            color='whatsapp.500'
            onClick={(e) => copyUrl(e, sheetsServiceId)}
            icon={<MdContentCopy />}/>{sheetsServiceId}  
        </Code> as an editor to your sheet.
      </AlertDescription>
    </Alert>
  );
};