import { TeamMember } from "../team"

export interface Chatbot {
  bot_type: string
  completed_reps: number
  created_at: string
  data_source: any
  data_source_count: number
  id: string
  is_active: boolean
  org_id: string
  org_name: string
  quick_bot: boolean
  raw_data_sync: boolean
  sheet_columns: number
  sheet_id: string
  sheets_row_id_support: boolean
  short_code: string
  task_count: number
  task_list: TaskProperties[]
  template: string
  title: string
  total_reps: number
  updated_at: string
  stats: ChatbotStats
  team: TeamMember[]
  qr_code: QRCode
  add_users_to_team: boolean
}

export interface TaskProperties {
  id: string
  task_name: string
  description: string
  name: string
  task_type: string
  total_repetitions: number
  days: number
}

export interface Task {
  id: string
  task_id: string
  chatbot_code: string
  task_name: string
  rep_status: number
  rep_type: string
  notes: string
  updated_at: string
  state_changed_at: string
}

export interface QRCode {
  code: string
  qr_image_url: string
  deep_link_url: string
  prefilled_message: string
}

export interface ChatbotStats {
  pending_tasks: number
  completed_tasks: number
}
export interface CreateChatbotDto {
  template: string;
  sheet_id: string;
}

export interface UpdateChatbotDto {
  template: string;
  sheet_id: string;
}

export enum TemplateType {
  SOP = 'sop',
  FORM = 'form',
}


export interface UpdateSettingsDto {
  enable_quick_bot: boolean;
  enable_raw_data_sync: boolean;
  add_users_to_team: boolean;
} 

export interface ChatbotFilters {
  orgId?: string;
  search?: string;
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface ReportFilters {
    offset?: number;
    limit?: number;
    start_date: string;
    end_date: string;
}