import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  Box,
  Image,
} from '@chakra-ui/react';
import Select from 'react-select';
import allCountries from 'world-countries';
import { Controller } from 'react-hook-form';

const formattedCountries = allCountries.map((country) => ({
  value: (country.idd.root + (country.idd.suffixes?.[0] || '')).replace(/\s/g, ''),
  label: country.name.common,
  flag: `https://flagcdn.com/${country.cca2.toLowerCase()}.svg`,
}));

interface PhoneNumberFieldProps {
  control: any;
  errors: any;
  required?: boolean;
  defaultCountryCode?: string;
  defaultPhone?: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    width: '140px',
    border: '1px solid',
    borderColor: '#E2E8F0',
    '&:hover': {
      borderColor: '#CBD5E0',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#25D366' : state.isFocused ? '#E6FFE6' : 'white',
    color: state.isSelected ? 'white' : '#4d3d2d',
    '&:active': {
      backgroundColor: '#25D366',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    zIndex: 9999,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#4d3d2d',
  }),
};

const formatOptionLabel = ({ value, label, flag }: any) => (
  <HStack spacing={2}>
    <Image src={flag} alt={label} boxSize="20px" objectFit="cover" />
    <Box>{value}</Box>
  </HStack>
);

export const PhoneNumberField = ({ 
  control, 
  errors, 
  required = false,
  defaultCountryCode,
  defaultPhone,
}: PhoneNumberFieldProps) => {

  return (
    <FormControl isInvalid={!!errors.phone_number || !!errors.country_code} isRequired={required}>
      <FormLabel color="#4d3d2d">Phone Number</FormLabel>
      <HStack spacing={4}>
        <Controller
          name="country_code"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              options={formattedCountries}
              defaultValue={defaultCountryCode}
              value={formattedCountries.find(option => option.value === value) || null}
              onChange={(option) => onChange(option?.value || '')}
              formatOptionLabel={formatOptionLabel}
              styles={customStyles}
              placeholder="Code"
            />
          )}
        />
        <Controller
          name="phone_number"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              value={value || ''}
              defaultValue={defaultPhone}
              onChange={(e) => onChange(e.target.value)}
              type="tel"
              placeholder="Phone number"
              bg="white"
              flex={1}
            />
          )}
        />
      </HStack>
      {errors.phone_number && (
        <FormErrorMessage>{errors.phone_number.message}</FormErrorMessage>
      )}
      {errors.country_code && (
        <FormErrorMessage>{errors.country_code.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};