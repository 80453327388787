import {
  Heading,
  Text,
  Button,
  HStack,
  Card,
  CardBody,
  Badge,
} from '@chakra-ui/react';
import { MdContentCopy, MdOpenInNew } from 'react-icons/md';

interface TemplateCardProps {
  title: string;
  description: string;
  sheetUrl: string;
  type: string;
}

export const TemplateCard = ({ title, description, sheetUrl, type }: TemplateCardProps) => {
  return (
    <Card
      bg="#faeed9"
      shadow="md"
      _hover={{ shadow: 'lg' }}
      transition="all 0.2s"
      backgroundImage="linear-gradient(to bottom right, rgba(250, 238, 217, 0.98), rgba(250, 238, 217, 0.95))"
    >
      <CardBody>
        <HStack spacing={2} mb={2}>
          <Heading size="md" color="#4d3d2d">
            {title}
          </Heading>
          <Badge colorScheme="whatsapp" fontSize="xs">
            {type}
          </Badge>
        </HStack>
        <Text color="gray.600" mb={4}>
          {description}
        </Text>
        <HStack spacing={4}>
          <Button
            leftIcon={<MdOpenInNew />}
            size="sm"
            onClick={() => window.open(`${sheetUrl}/edit`, '_blank')}
          >
            View Template
          </Button>
          <Button
            leftIcon={<MdContentCopy />}
            size="sm"
            variant="ghost"
            onClick={() => window.open(`${sheetUrl}/copy`, '_blank')}
          >
            Copy template
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
};