import axios from 'axios';

// Get environment variables
const API_URL = import.meta.env.VITE_APP_APISERVER;
const API_TIMEOUT = Number(import.meta.env.VITE_API_TIMEOUT) || 30000;
const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX


// Create axios instance with default config
export const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from storage
    const token = localStorage.getItem(`${cookiePrefix}_token`);
    
    // If token exists, add to headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        
        if (refreshToken) {
          const response = await axiosInstance.post('/auth/refresh', {
            refreshToken,
          });

          const { token } = response.data;
          localStorage.setItem('auth_token', token);

          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axiosInstance(originalRequest);
        }
      } catch (error) {
        // Handle refresh token failure (e.g., logout user)
        localStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/auth/login';
      }
    }

    return Promise.reject(error);
  }
);