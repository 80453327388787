import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@/components/Auth/ProtectedRoute';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import CreateChatbot from './pages/CreateChatbot';
const DashboardLayout = lazy(() => import('@/components/DashboardLayout'));
const LandingPage = lazy(() => import('@/pages/LandingPage'));
const LandingPageV2 = lazy(() => import('@/pages/LandingPageAlt1'));
const AuthPage = lazy(() => import('@/pages/AuthPage'));
const TermsPage = lazy(() => import('@/pages/TermsPage'));
const AboutPage = lazy(() => import('@/pages/AboutPage'));
const PrivacyPage = lazy(() => import('@/pages/PrivacyPage'));
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const UsersPage = lazy(() => import('@/pages/UsersPage'));
const CalendarPage = lazy(() => import('@/pages/CalendarPage'));
const OrganizationsPage = lazy(() => import('@/pages/OrganizationsPage'));
const CalendarUserMapping = lazy(() => import('@/pages/CalendarUserMapping'));
const ReportsPage = lazy(() => import('@/pages/ReportsPage'));
const adminPath = import.meta.env.VITE_ADMIN_URL_PATH
const LazyWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<LoadingSpinner />}>
    {children}
  </Suspense>
);

export const createRoutes = () => [
    {
      path: '/',
      element: <LazyWrapper><LandingPage /></LazyWrapper>,
    },
    {
      path: '/v2',
      element: <LazyWrapper><LandingPageV2 /></LazyWrapper>,
    },
    {
      path: '/auth/:mode',
      element: <LazyWrapper><AuthPage /></LazyWrapper>,
    },
    {
      path: `${adminPath}/orgs`,
      element: <OrganizationsPage/>
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <LazyWrapper>
            <DashboardLayout />
          </LazyWrapper>
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'dashboard/:orgId',
          element: <LazyWrapper><Dashboard /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/team',
          element: <LazyWrapper><UsersPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/scheduler',
          element: <LazyWrapper><CalendarPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/create-chatbot',
          element: <LazyWrapper><CreateChatbot /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/reports',
          element: <LazyWrapper><ReportsPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/scheduler/:eventId/users',
          element: <LazyWrapper><CalendarUserMapping /></LazyWrapper>,
        },
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ],
    },
    {
      path: "/terms",
      element: <TermsPage/>
    },
    {
      path: "/about",
      element: <AboutPage/>
    },
    {
      path: "/privacy",
      element: <PrivacyPage/>
    },
];