import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Textarea,
  Switch,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useState } from 'react';
import { UseFormRegister, FieldError } from 'react-hook-form';

interface FormFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  type?: 'text' | 'email' | 'password' | 'select' | 'textarea' | 'switch' | 'tel';
  options?: { value: string; label: string }[];
  placeholder?: string;
  required?: boolean;
}

export const FormField = ({
  label,
  name,
  register,
  error,
  type = 'text',
  options = [],
  placeholder,
  required = false,
}: FormFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const renderField = () => {
    switch (type) {
      case 'password':
        return (
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...register(name)}
              placeholder={placeholder}
              bg="white"
            />
            <InputRightElement>
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                icon={showPassword ? <MdVisibilityOff size={18} /> : <MdVisibility size={18} />}
                onClick={() => setShowPassword(!showPassword)}
                variant="ghost"
                size="sm"
              />
            </InputRightElement>
          </InputGroup>
        );
      case 'select':
        return (
          <Select {...register(name)} placeholder={placeholder} bg="white">
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        );
      case 'textarea':
        return (
          <Textarea
            {...register(name)}
            placeholder={placeholder}
            bg="white"
            rows={4}
          />
        );
      case 'switch':
        return <Switch {...register(name)} />;
      default:
        return (
          <Input
            type={type}
            {...register(name)}
            placeholder={placeholder}
            bg="white"
          />
        );
    }
  };

  return (
    <FormControl isInvalid={!!error} isRequired={required}>
      <FormLabel color="#4d3d2d">{label}</FormLabel>
      {renderField()}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};