import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthApi } from './api';
import type { LoginDto, SignupDto } from './types';

const QUERY_KEYS = {
  all: ['auth'] as const,
  user: () => [...QUERY_KEYS.all, 'user'] as const,
};


export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: LoginDto) => AuthApi.login(data),
    onSuccess: (response) => {
      localStorage.setItem('auth_token', response.token);
      localStorage.setItem('refresh_token', response.refreshToken);
      queryClient.setQueryData(QUERY_KEYS.user(), response);
    },
  });
};

export const useSignup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SignupDto) => AuthApi.signup(data),
    onSuccess: (response) => {
      localStorage.setItem('auth_token', response.token);
      localStorage.setItem('refresh_token', response.refreshToken);
      queryClient.setQueryData(QUERY_KEYS.user(), response);
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => AuthApi.logout(),
    onSuccess: () => {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('refresh_token');
      queryClient.setQueryData(QUERY_KEYS.user(), null);
      queryClient.clear();
    },
  });
};