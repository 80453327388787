import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { User } from '@/lib/sdk/auth';

export const useAuth = () => {
  const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
  let jsonUser = localStorage.getItem(`${cookiePrefix}_user`)
  let user: User | null = null
  if (jsonUser) {
    user = JSON.parse(jsonUser)
  } 

  return {
    user: user ?? null,
    isAuthenticated: !!user,
    isLoading: false,
  };
};

export const useRequireAuth = (redirectTo: string = '/auth/login') => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(redirectTo, {
        replace: true,
        state: { from: location.pathname },
      });
    }
  }, [isAuthenticated, isLoading, navigate, redirectTo, location]);

  return { isLoading };
};