import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: "'Ubuntu', sans-serif",
    body: "'Ubuntu', sans-serif",
  },
  styles: {
    global: {
      body: {
        bg: '#fffaf5',
        color: '#4d3d2d',
      },
    },
  },
  components: {
    Button: {
      variants: {
        solid: {
          bg: '#25D366',
          color: 'white',
          _hover: {
            bg: '#4d3d2d',
          },
        },
        ghost: {
          color: '#4d3d2d',
          _hover: {
            bg: '#4d3d2d',
            color: '#fff9f0',
          },
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    IconButton: {
      variants: {
        solid: {
          bg: '#25D366',
          color: 'white',
          _hover: {
            bg: '#4d3d2d',
          },
        },
        ghost: {
          color: '#4d3d2d',
          _hover: {
            bg: '#4d3d2d',
            color: '#fff9f0',
          },
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: '#fff9f0',
          borderWidth: 0,
          shadow: 'lg',
          color: '#4d3d2d',
        },
        item: {
          bg: 'transparent',
          _hover: {
            bg: '#4d3d2d',
            color: '#fff9f0',
          },
          _focus: {
            bg: '#4d3d2d',
            color: '#fff9f0',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: '#4d3d2d',
        fontFamily: "'Ubuntu', sans-serif",
      },
    },
    Heading: {
      baseStyle: {
        color: '#4d3d2d',
        fontFamily: "'Ubuntu', sans-serif",
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            color: '#4d3d2d',
            fontFamily: "'Ubuntu', sans-serif",
            _placeholder: {
              color: '#4d3d2d',
              opacity: 0.7,
            },
          },
        },
      },
    },
    Table: {
      baseStyle: {
        th: {
          color: '#4d3d2d',
          fontFamily: "'Ubuntu', sans-serif",
        },
        td: {
          color: '#4d3d2d',
          fontFamily: "'Ubuntu', sans-serif",
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            color: '#4d3d2d',
            fontFamily: "'Ubuntu', sans-serif",
          },
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          bg: '#faeed9',
        },
      },
    },
    Code: {
      baseStyle: {
        bg: '#faeed9',
        color: '#4d3d2d',
        fontFamily: "'Ubuntu', sans-serif",
      },
    },
  },
});

export default theme;