import {
  Box,
  Heading,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { InfoMessage } from '@/components/Chatbot/InfoMessage';
import { TemplateGrid } from '@/components/Chatbot/TemplateGrid';
import { ChatbotForm, ChatbotFormData } from '@/components/Chatbot/ChatbotForm';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import {  useCreateChatbot } from '@/lib/sdk/chatbot';

export const CreateChatbot = () => {
  const toast = useToast();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const createChatbotMutation = useCreateChatbot();

  const breadcrumbItems = [
    { label: 'Dashboard', href: `/dashboard/${orgId}` },
    { label: 'Create Chatbot' },
  ];

  const handleSubmit = async (data: ChatbotFormData) => {
    try {
      const sheetId = data?.sheet_url.split('/')[5]
      await createChatbotMutation.mutateAsync({
        template: data.template,
        sheet_id: sheetId,
      });
      toast({
        title: 'Chatbot created successfully',
        status: 'success',
        duration: 3000,
      });
      navigate(`/dashboard/${orgId}`);
    } catch (error: any) {
      toast({
        title: 'Error creating chatbot',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Box>
      <VStack align="stretch" spacing={6}>
        <Breadcrumbs items={breadcrumbItems} />
        
        <Heading size={{ base: "md", md: "lg" }}>
          Create New Chatbot
        </Heading>
        
        <Text color="gray.600">
          Choose from our templates or create your own chatbot using a Google Sheet
        </Text>

        <InfoMessage />
        
        <Box>
          <Heading size="md" mb={4}>
            Sample Templates
          </Heading>
          <TemplateGrid />
        </Box>

        <Box>
          <Heading size="md" mb={4}>
            Create Chatbot
          </Heading>
          <ChatbotForm onSubmit={handleSubmit} />
        </Box>
      </VStack>
    </Box>
  );
};

export default CreateChatbot;