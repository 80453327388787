import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const location = useLocation();

  return (
    <Breadcrumb 
      spacing="8px" 
      separator={<MdChevronRight color="#4d3d2d" />}
      mb={6}
      color="#4d3d2d"
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        const isActive = location.pathname === item.href;

        return (
          <BreadcrumbItem 
            key={index}
            isCurrentPage={isLast}
          >
            {item.href && !isLast ? (
              <BreadcrumbLink
                as={Link}
                to={item.href}
                color={isActive ? 'whatsapp.500' : 'inherit'}
                _hover={{ color: 'whatsapp.500' }}
              >
                {item.label}
              </BreadcrumbLink>
            ) : (
              <Text
                color={isLast ? 'whatsapp.500' : 'inherit'}
                fontWeight={isLast ? 'semibold' : 'normal'}
              >
                {item.label}
              </Text>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};