import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormField } from './FormField';
import { PhoneNumberField } from './PhoneNumberField';

const userSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  country_code: z.string().min(1, 'Country code is required'),
  phone_number: z.string().min(1, 'Phone number is required')
    .regex(/^\d{10}$/, 'Phone number must be 10 digits'),
});

export type UserFormData = z.infer<typeof userSchema>;

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: UserFormData) => void;
  initialData?: UserFormData;
  isEditing?: boolean;
}

export const UserModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  isEditing = false,
}: UserModalProps) => {
  const form = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: initialData || {
      name: '',
      phone_number: '',
      country_code: '+91',
    },
    values: initialData,
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent bg="#fff9f0">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader color="#4d3d2d">
            {isEditing ? 'Edit User' : 'Add User'}
          </ModalHeader>
          <ModalCloseButton color="#4d3d2d" />
          
          <ModalBody>
            <VStack spacing={4}>
              <FormField
                label="Name"
                name="name"
                register={form.register}
                error={form.formState.errors.name}
                required
              />
              <PhoneNumberField
                control={form.control}
                errors={form.formState.errors}
                required
                defaultCountryCode={initialData?.country_code}
                defaultPhone={initialData?.phone_number}
              />
            </VStack>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button
              variant="ghost"
              onClick={onClose}
              isDisabled={form.formState.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={form.formState.isSubmitting}
              loadingText="Submitting"
            >
              {isEditing ? 'Update' : 'Add'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;