import { SimpleGrid } from '@chakra-ui/react';
import { TemplateCard } from './TemplateCard';

const templates = [
  {
    title: 'Simple SOP Bot',
    description: 'Track daily tasks that need to be done only once a day.',
    sheetUrl: 'https://docs.google.com/spreadsheets/d/1m-uEmsUvLxJl22-p28hy1O0s2Vmiygd-mmg5MF5ZRt0',
    type: 'Sop',
  },
  {
    title: 'Simple SOP with reps Bot',
    description: 'Track daily tasks that have to be repeted more than once a day.',
    sheetUrl: 'https://docs.google.com/spreadsheets/d/1UahSxWnbtDZ_hQKcMZB3mPGlrwBO0FKqbvG-NUSwiAU',
    type: 'Sop',
  },
  {
    title: 'Attendance Bot',
    description: 'Track daily attendance, number of hours worked etc, checkin verification etc.',
    sheetUrl: 'https://docs.google.com/spreadsheets/d/1sddaUBjbFQHU4lIN3lv_ePuw70RKa3mh8lYGedxOFn4',
    type: 'Attendance',
  },
  {
    title: 'Form filling Bot',
    description: 'Collecting data from a customer, via a set of questions.',
    sheetUrl: 'https://docs.google.com/spreadsheets/d/1jpcHgGc74KUxtYcIcveFS9bA0abctSIt9d8-1JkW7ZA',
    type: 'Forms',
  },
];

export const TemplateGrid = () => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
      {templates.map((template, index) => (
        <TemplateCard key={index} {...template} />
      ))}
    </SimpleGrid>
  );
};