import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormField } from './FormField';
import { PhoneNumberField } from './PhoneNumberField';

const organizationSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email address'),
  country_code: z.string().min(1, 'Country code is required'),
  phone_number: z.string().min(1, 'Phone number is required')
    .regex(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number'),
});

export type OrganizationFormData = z.infer<typeof organizationSchema>;

interface OrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: OrganizationFormData) => void;
  initialData?: OrganizationFormData;
  isEditing?: boolean;
}

export const OrganizationModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  isEditing = false,
}: OrganizationModalProps) => {
  const form = useForm<OrganizationFormData>({
    resolver: zodResolver(organizationSchema),
    defaultValues: initialData || {
      name: '',
      email: '',
      phone_number: '',
      country_code: '+91',
    },
    values: initialData,
  });



  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="md">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent bg="#fff9f0">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader color="#4d3d2d">
            {isEditing ? 'Edit Organization' : 'Add Organization'}
          </ModalHeader>
          <ModalCloseButton color="#4d3d2d" />
          
          <ModalBody>
            <VStack spacing={4}>
              <FormField
                label="Organization Name"
                name="name"
                register={form.register}
                error={form.formState.errors.name}
                required
                placeholder="Enter organization name"
              />
              <FormField
                label="Email"
                name="email"
                type="email"
                register={form.register}
                error={form.formState.errors.email}
                required
                placeholder="Enter email address"
              />
               <PhoneNumberField
                control={form.control}
                errors={form.formState.errors}
                required
                defaultCountryCode={initialData?.country_code}
                defaultPhone={initialData?.phone_number}
              />
            </VStack>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button
              variant="ghost"
              onClick={handleClose}
              isDisabled={form.formState.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={form.formState.isSubmitting}
              loadingText="Submitting"
            >
              {isEditing ? 'Update' : 'Add'}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};